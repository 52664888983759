import { personalUser, personalUserMenu } from '../../services/api/user.js'
import Vue from 'vue'

const state = {
  currentUser: null,
  hasMenu: [],
}

const mutations = {
    setCurrentUser (state, payload) {
        state.currentUser = payload 
    },
    setUserMenu (state, payload) {
        state.hasMenu = payload 
    } 
}

const actions = {
    getInfoUserPersonal ({ state, commit }) {
        return new Promise((resolve,reject) => {
            if (state.currentUser == null) {
                var checkPersonalUser = personalUser();
                checkPersonalUser.then(r => {
                    console.log(r.data)
                    if (r.data != undefined) {
                        commit("setCurrentUser",r.data)
                        resolve(true)
                    } else {
                        commit("setCurrentUser",null)
                        commit("setUserMenu",[])
                        reject(true)
                    }
                })
            } else {
                resolve(true)
            }
        })
    },
    destroyUserPersonal ({ commit }) {
        commit("setCurrentUser",null)
        commit("setUserMenu",[])
    },
    getMenuUser ({ state, commit }) {
        return new Promise((resolve) => {
            if (state.hasMenu.length == 0) {
                var checkPersonalUser = personalUserMenu();
                checkPersonalUser.then(r => {
                    commit("setUserMenu",r.data)
                    resolve(true)
                })
            } else {
                resolve(true)
            }
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
  }
  